@import '../../../../scss/theme-bootstrap';

/* PRECHAT OVERLAY UI */
.prechat-overlay {
    .prechat-container {
      .icon--close {
        display: none;
      }
      &__header {
        margin: 0 0 15px;
        padding-bottom: 15px;
        line-height: 1;
        border-bottom: $border;
      }
      &__instructions,
      &__required {
        margin-bottom: 15px;
        line-height: 1;
      }
      .prechat-form {
        &__name {
          position: relative;
          .prechat-form__input {
            margin-#{$rdirection}: 4%;
          }
        }
        &__signout {
          position: absolute;
          top: 42px;
        }
        .form-col-one,
        .form-col-two {
          float: $ldirection;
          width: 48%;
        }
        .form-col-two {
          float: $rdirection;
        }
        &__input {
          width: 100%;
        }
        &__question {
          clear: both;
          #textarea-your-question {
            width: 100%;
          }
        }
        &__contact-us a {
          text-decoration: underline;
          text-transform: uppercase;
        }
        &__btn-container {
          width: 100%;
          display: inline-block;
        }
        &__order-chat,
        &__beauty-chat {
          width: 50%;
          float: $ldirection;
        }
        &__message {
          padding: 0 0 20px;
          margin-top: 5px;
        }
        &__contact-us {
          p {
            margin: 1em 0 0;
          }
        }
      }
    }
  }
  /* STICKY CHAT UI */
  .sticky-livechat {
    &__copy,
    &__cta {
      margin: 0;
    }
    &__cta {
      border-bottom: solid 1px;
      text-decoration: none !important;
    }
    &-content.integrated-prechat {
      display: block;
      margin-top: -38px;
      margin-#{$ldirection}: -12px;
      width: 213px;
      background-color: $white;
      border: 1px solid $color-off-black;
    }
    .prechat-container {
      padding: 10px;
      div.icon--close {
        text-align: $rdirection;
        width: 100%;
        font-size: 20px;
        font-weight: bold;
      }
      &__header {
        @include h1;
        margin: 0 0 10px;
        color: $color-off-black;
      }
      &__content {
        text-align: $ldirection;
      }
      &__required {
        margin: 10px 0;
      }
      .prechat-form {
        &__signout {
          margin-bottom: 10px;
        }
        &__input {
          height: 40px;
          padding: 10px;
          overflow: hidden;
        }
        &__question textarea {
          height: 60px;
          padding: 0 10px;
        }
        &__input,
        &__question textarea {
          width: 100%;
          margin-bottom: 10px;
        }
        &__order-chat,
        &__beauty-chat {
          margin-bottom: 10px;
        }
        &__order-btn,
        &__beauty-btn {
          width: 100%;
          margin-bottom: 10px;
          img {
            width: 100%;
          }
        }
      }
    }
    &__icon {
      fill: $white;
      height: 20px;
      width: 20px;
      margin-#{$rdirection}: 5px;
    }
  }
  